import React, { Component } from "react";
import Modal from "react-modal";
import ToggleButton from 'react-toggle-button';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import arrayMove from 'array-move';
import { deepCopy } from "../../util/helper";
import { PANEL_QUESTION_IDS } from "../../util/constants";
import { updateScript } from "../../redux/SelectedCampaign/actions";

import { 
  PlusButton, 
  TimesButton 
} from "../generic/RoundButtons/RoundButtons";
import { 
  ADMIN_QUESTION_TYPES,
  createDefaultEmptyQuestion, 
  createDefaultIntroQUestion, 
  createDefaultPanelQuestions, 
  QUESTION_TYPES
} from "./scriptUtil";
import AddQuestion from "./AddQuestion";
import { Question } from "./Question";

import { getFilterPanelQuestionsFunc } from "../Script";
import { BulmaButton } from "../generic/Buttons/BulmaButton";
import JsonEditor from './jsonEditor';
import ScriptErrorBoundary from './ScriptErrorBoundary';
import MediaListContainer from "../MediaList/MediaListContainer";
// import { saveCampaignName } from "../../redux/SelectedCampaign/actions";

/**
 * Script and Prompt function exactly the same.
 * By simply passing props to this component we can satisfy both.
 *
 * subject: {string} What the subject of the editor is (Prompt or Script)
 * script: {array} The prompt or script.
 * updateScript: {function} The update function for script or prompt.
 * list: {array} The list of phone numbers from selectedCampaignData
 */
export class ScriptEditorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      // I'll use script but it can be prompt as well
      // localScript: deepCopy(this.props.script),
      // initialConditionals: deepCopy(this.props.initialConditionals),
      defaultFirstQuestionId: this.props.defaultFirstQuestionId,
      dirty: false,
      validated: false,
	  	jsonEditorOpen:false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.selectedCampaignIndex !== prevState.selectedCampaignIndex ||
      (nextProps.script[0] !== prevState.localScript[0] && !prevState.dirty) ||
      nextProps.subject !== prevState.subject
    ) {
      return {
        localScript: nextProps.script,
        initialConditionals: nextProps.initialConditionals,
        selectedCampaignIndex: nextProps.selectedCampaignIndex,
        dirty: false,
        subject: nextProps.subject
      };
    }

    return null;
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    });
  }

  save = () => {
    if (this.state.dirty) {
      let state = deepCopy(this.state);

      // Before saving, validate the GOTOs and ids to make sure none of them are invalid.
      state = this.validateGOTOValues(state);
      this.props.updateScript(state.localScript, state.initialConditionals, state.defaultFirstQuestionId);
    }
  }

    // Check all GOTOs are valid Question IDs
    // Warning if conditionals create infinite loops
    // Warnings if conditionals are impossible to reach
    // Warning if script uses data variable not in list
    // Warning if script reflogic ers to “prior” questions or parameters that don’t exist
    // Warning if syntax does not follow standards like matching parentheses or missing terms (like nothing following an AND term)
  validateScript = () => {
    const {localScript, initialConditionals, defaultFirstQuestionId} = this.state;
    // Has the same structure as the state so we can match the warning with the field.
    let scriptWarnings = {
      localScript: localScript.map(item => ({
        question: this.checkStringForBadFields(item.question),
        conditionalNexts: item.conditionalNexts.map(c => ({
          goto: localScript.find(item => item.id === c.goto) !== undefined ? "" : "ID does not match any question.",
          condition: this.checkStringForBadFields(c.condition)
        }))
      })),
      // Check if all init conditionals point to
      initialConditionals:  initialConditionals.map(c => ({
        goto: localScript.find(item => item.id === c.goto) !== undefined ? "" : "ID does not match any question.",
        condition: this.checkStringForBadFields(c.condition)
      })),
      defaultFirstQuestionId: "",
      terminatingQuestion: !localScript.find(item => item.type === "terminating") ? "No terminating questions" : "",
      closingQuestion: !localScript.find(item => item.type === "closing") ? "No closing questions" : ""
    };

    this.setState({
      warnings: scriptWarnings,
      validated: true
    }, () => console.log(this.state.warnings));

  }

  copyQuestionToClipboard = (qIndex, e) => {
    this.setState({
      clipboard: {...this.state.localScript[qIndex]}
    });
  }

  pasteQuestion = (qIndex, e) => {
    const script = [...this.state.localScript];
    const clipboard = {...this.state.clipboard}

    if (script.find(item => item.id === clipboard.id)) {
      clipboard.id += " (Copy)";
    }

    script.splice(qIndex + 0.5, 0, clipboard);

    this.setState({
      localScript: script,
      dirty: true,
      validated: false
    })
  }

  /**
   * Process a string and check for open {} or [], or other issues.
   */
  checkStringForBadSyntax = (text) => {
    return false;
  }

  /**
   * Process a string and check for fields {f} or questions {id},
   * that don't exist.
   */
  checkStringForBadFields = (text) => {
    let variables = text.match(/\{([^\{\}]+)\}/g); // matches {...}
    let ids = text.match(/\[([^\[\]]+)\]/g); // matches [...]

    if (!variables && !ids) {
      return true;
    }

    const {list, displayIndex} = this.props;

    const phones = Object.keys(list);
    const availableVariables = Object.keys(list[phones[displayIndex]].misc);
    let result = true;

    if (variables && !variables.reduce((previous, current) => availableVariables.includes(current.slice(1, -1)) && previous, true))
      return "Variable not in list."

    if (ids && !ids.reduce((previous, current) => this.state.localScript.map(item => item.id).includes(current.slice(1, -1)) && previous, true))
      return "Question ID not found."


    return "";
  }

  handleQuestionTextChange = (i, event) => {
    let lp = deepCopy(this.state.localScript);
    lp[i].question = event.target.value;
    this.setState({
      localScript: lp,
      dirty: true
    });
    if (event.target.value.length > 160) {
      event.target.style.backgroundColor = "#ee0000";
    } else {
      event.target.style.backgroundColor = "#ffffff";
    }
  }

  handleQuestionTypeChange = (i, event) => {
    let script = deepCopy(this.state.localScript)
    script[i].type = event.target.value

    if (event.target.value === "panel") {
      const { panel_opt_in, panel_accept, panel_refuse } = createDefaultPanelQuestions()

      script[i] = panel_opt_in;
      i += 0.5;
      script.splice(i + 0.5, 0, panel_accept, panel_refuse);
    }

    this.setState({
      localScript: script,
      dirty: true,
      validated: false
    })
  }

  handleQuestionIdChange = (qIndex, event) => {
    const newID = event.target.value;
    let updatedState = this.updateConditionalsQuestionIdValues({...this.state}, newID, qIndex);

    // Since this comes from updateConditionalsQuestionIdValues
    // It's already a copy of localScript and is not mutating state
    updatedState.localScript[qIndex] = {...updatedState.localScript[qIndex], id: newID};

    // THis should do it
    this.setState({
      ...updatedState,
      dirty: true,
      validated: false
    }, () => console.log("state: ", this.state)); //debug
  }

  handleQuestionMediaChange = (qIndex, newMediaId) => {
    const newScript = this.state.localScript.map(
      (item, i) => (i === qIndex ? ({...item, s160MediaId: newMediaId}) : {...item}));

    // THis should do it
    this.setState({
      localScript: newScript,
      dirty: true,
      validated: false
    }, //() => console.log("state: ", this.state)
    ); //debug
  } 

  /**
   * ( #158: This solves the issue of IDs not updating. )
   * Repurposed as a update method for a local state object
   *
   * @param {object} state We need to pass a local state so we don't updated the existing one, nor look up outdated info.
   * @param {string} newID The new id that will replace the old in the state.
   * @param {int} qIndex The position in the script of the question.
   * @param {bool} shift If shift is true then we need to check that the newID is a valid option shift includes deleting, moving etc.
   */
  updateConditionalsQuestionIdValues = (state, newID, qIndex, shift) => {
    let script = [...state.localScript];
    let initCond = [...state.initialConditionals];
    const prevID = state.localScript[qIndex].id;

     // Update default conditional
     const defaultFirstQuestionId = prevID === state.defaultFirstQuestionId ? newID : state.defaultFirstQuestionId;
     // Update other init conditionals
     initCond = initCond.map((c, cid) => {
       if (c.goto === prevID) {
         return {...c, goto: newID};
       }
       return c;
     });

    // Need to account for which questions are available to each conditionals
    script = script.map((q, qid) => {
      // Update default next for each question
      let defaultNext = q.defaultNext;
      if (q.type !== "closing" && q.type !== "terminating" && defaultNext === prevID) {
        if (shift) {
            const qAfter = this.getQuestionsAfterInArray(qid, script);
          // If the newID is in the nextQuestions use it
          if (qAfter.length !== 0 && qAfter.find(item => item.id === newID)) {
            defaultNext = newID;
          } else {
            // If not use the next available question or blank if none
            defaultNext = qAfter.length !== 0 ? qAfter[0].id : "";
          }
        } else {
          // If we're not shifting then just use the newID
          defaultNext = newID;
        }
      }
      return {
        ...q,
        defaultNext: defaultNext,
        // Update all conditionals with the new values
        conditionalNexts: q.conditionalNexts.map((c, cid) => {
          if (c.goto === prevID) {
            let updatedNewID = newID;
            if (shift) {
              const qAfter = this.getQuestionsAfterInArray(qid, script);
              // If the newID is in the nextQuestions use it
              if (!qAfter.find(item => item.id === prevID)) {
                // If not use the next available question or blank if none
                updatedNewID = qAfter.length !== 0 ? qAfter[0].id : "";
              }
            }
            return {...c, goto: updatedNewID};
          }
          return c;
        })};
     });


     return {
      localScript: script,
      defaultFirstQuestionId: defaultFirstQuestionId,
      initialConditionals: initCond
    };
  }

  /**
   * A simple utility to make sure the question id is valid in its current position.
   *
   * @param {int} qIndex The index of the position in the script of the question (-1 for initConditionals or defaultFirstQuestion)
   * @param {array} script The script (we pass it as a param since we usually validate after changes)
   * @param {string} currentId The id we want to verify or replace
   */
  validateIdOrGetNextValid = (qIndex, script, currentId) => {
    const validIdChoices = this.getQuestionsAfterInArray(qIndex, script);
    if (!validIdChoices || !validIdChoices.length) { return; }
    const newId = validIdChoices.find(item => item.id === currentId) ? currentId : validIdChoices[0].id;
    return newId;
  }

   /**
   * GOTO values cannot be set to a question that preceeds the current one or non existing one. So if it is:
   * - Set it to the first available question id
   * - If none exists, set to undefined
   * @param {object} state We need to pass a local state so we don't updated the existing one, nor look up outdated info.
   */
  validateGOTOValues = (state) => {
    let script = [...state.localScript];
    let initCond = [...state.initialConditionals];

     return {
      localScript: script
        // .filter(q => q.type !== "closing" && q.type !== "terminating")
        .map((q, index) => (q.type !== "closing" && q.type !== "terminating" ? {
            ...q,
            defaultNext: this.validateIdOrGetNextValid(index, script, q.defaultNext),
            conditionalNexts: q.conditionalNexts
              .map(c => ({...c, goto: this.validateIdOrGetNextValid(index, script, c.goto)}))
          } : q)),
      defaultFirstQuestionId: this.validateIdOrGetNextValid(-1, script, state.defaultFirstQuestionId),
      initialConditionals: initCond
        .map(c => ({...c, goto: this.validateIdOrGetNextValid(-1, script, c.goto)}))
    };
  }

  //
  handleCheckboxChange = (qIndex, e) => {
    let script = deepCopy(this.state.localScript);
    script[qIndex] = { ...script[qIndex], [e.target.name] : e.target.checked };

    this.setState({
      localScript: script,
      dirty: true,
      validated: false
    }, () => console.log(this.state));
  }

  /**
   * Add an empty question of any type
   * @param {*} i The index in front of which to add the question
   * @param {*} question_type
   */
  addQuestion = (i, question_type) => {
    let script = deepCopy(this.state.localScript);

    if (question_type === "intro") {
      script.splice(i + 1, 0, createDefaultIntroQUestion());

    } else if (question_type === "panel") {
      const { panel_opt_in, panel_accept, panel_refuse } = createDefaultPanelQuestions()

      script.splice(i + 1, 0, panel_opt_in, panel_accept, panel_refuse);
    } else {
      // All other types are the same
      script.splice(i + 1, 0, createDefaultEmptyQuestion(i, script, question_type));
    }

    this.setState({
      localScript: script,
      dirty: true,
      validated: false
    });
  }

  // The edge cases for moving a question are:
  //  - when the question before it has links to it and they switch places.
  //  - when the moved question has links to the next question and they switch.
  // In both cases the values need to be adjusted as the links won't work.
  moveQuestion = (qIndex, moveBy) => {
    // The id to be corrected is always the question that will move forward.
    // So either the question moving ->
    // or, if it's moving <-, the one before it.

    // Store which id to update before switching locations
    // Switch first then update id's so that getQuestionsAfter can get correct list
    let updatedState = {...this.state};
    updatedState.localScript = arrayMove([...updatedState.localScript], qIndex, Math.max(0, qIndex + moveBy));
    updatedState = this.validateGOTOValues(updatedState);

    this.setState({
      ...updatedState,
      dirty: true,
      validated: false
    }, ()=> console.log("state:", this.state));
  }

  /**
   * Deletes a question and updates ids and gotos to point to other questions.
   * @param {int} qIndex the index of the question to delete
   */
  deleteQuestion = (qIndex) => {
    let updatedState = deepCopy(this.state);
    // Remove the question then update the GOTOs and defaultNexts to match.
    updatedState.localScript.splice(qIndex, 1);
    updatedState = this.validateGOTOValues(updatedState);

    this.setState({
      ...updatedState,
      dirty: true,
      validated: false
    }, ()=> console.log("state:", this.state));
  }

  addAnswer = (qIndex) => {
    const script = deepCopy(this.state.localScript);
    const emptyAnswer = {
      // defaults
      value: getLowestUnusedValue(script[qIndex].answers),
      text: ''
    }
    script[qIndex].answers.push(emptyAnswer);
    this.setState({
      localScript: script,
      dirty: true,
      validated: false
    });

    function getLowestUnusedValue(answers) {
      const used = answers.map(a => parseInt(a.value))
      let val = 1
      while (used.includes(val)) {
        val++
      }
      return val
    }
  }

  deleteAnswer = (qIndex, aIndex) => {
    const script = deepCopy(this.state.localScript);
    script[qIndex].answers.splice(aIndex, 1)
    this.setState({
      localScript: script,
      dirty: true,
      validated: false
    });
  }

  handleAnswerChange = (qIndex, aIndex, event) => {
    const script = deepCopy(this.state.localScript);
    const answer = script[qIndex].answers[aIndex]
    const prop = event.target.name

    // warn if values not unique
    if (prop === 'value') {
      if (validateValue(event.target.value)) {
        event.target.classList.remove('is-danger')
      } else {
        event.target.classList.add('is-danger')
      }
    }
    answer[prop] = event.target.value
    this.setState({
      localScript: script,
      dirty: true,
      validated: false
    });

    function validateValue(enteredVal) {
      // can't be empty
      if (enteredVal == '') {
        return false
      }
      enteredVal = parseInt(enteredVal)
      // check if invalid integer (weird)
      if (isNaN(enteredVal)) {
        return false
      }
      //check that value has not been manually set to less than -1
      if (enteredVal < -1) return false;

      // check if duplicate value
      const usedVals = script[qIndex].answers.map(a => parseInt(a.value))
      return !usedVals.includes(enteredVal)
    }
  }

  handleDefaultFirstQuestionChange = (event) => {
    this.setState({
      defaultFirstQuestionId: event.target.value,
      dirty: true,
      validated: false
    })
  }

  handleInitialConditionalChange = (cIndex, event) => {
    const conditionals = deepCopy(this.state.initialConditionals)
    const prop = event.target.name
    conditionals[cIndex][prop] = event.target.value

    this.setState({
      initialConditionals: conditionals,
      dirty: true,
      validated: false
    });
  }

  addInitialConditional = () => {
    const conditionals = deepCopy(this.state.initialConditionals)
    const script = deepCopy(this.state.localScript)
    const emptyConditional = {
      // defaults
      condition: '',
      goto: script[0] ? script[0].id : undefined
    }

    conditionals.push(emptyConditional)
    this.setState({
      initialConditionals: conditionals,
      dirty: true,
      validated: false
    })
  }

  deleteInitialConditional = (cIndex) => {
    const conditionals = deepCopy(this.state.initialConditionals)
    conditionals.splice(cIndex, 1)
    this.setState({
      initialConditionals: conditionals,
      dirty: true,
      validated: false
    })
  }

  handleDefaultNextChange = (qIndex, event) => {
    const script = deepCopy(this.state.localScript);
    script[qIndex].defaultNext = event.target.value;
    this.setState({
      localScript: script,
      dirty: true,
      validated: false
    });

  }

  addConditional = (qIndex) => {
    const script = deepCopy(this.state.localScript);
    const emptyConditional = {
      // defaults
      condition: '',
      goto: script[qIndex + 1] ? script[qIndex + 1].id : undefined
    }
    script[qIndex].conditionalNexts.push(emptyConditional)
    this.setState({
      localScript: script,
      dirty: true,
      validated: false
    });
  }

  deleteConditional = (qIndex, cIndex) => {
    const script = deepCopy(this.state.localScript);
    script[qIndex].conditionalNexts.splice(cIndex, 1)
    this.setState({
      localScript: script,
      dirty: true,
      validated: false
    });
  }

  handleConditionalChange = (qIndex, cIndex, event) => {
    const script = deepCopy(this.state.localScript);
    const conditional = script[qIndex].conditionalNexts[cIndex]
    const prop = event.target.name
    conditional[prop] = event.target.value

    this.setState({
      localScript: script,
      dirty: true,
      validated: false
    });
  }

  handleJsonScriptChange = (e) => {
    e.persist();
    const script_promise = new Promise((resolve, reject)=>{
      try{
        resolve(JSON.parse(e.target.value))
      } catch(e) {
        reject()
      }

	  })
	  script_promise.then((script)=>{
		  this.setState(prevState =>({
			  localScript:script,
			  dirty: true,
			  validated: false
		  }))
	  })
  }
  /**
   * Returns list of questions in the STATE SCRIPT that come after the provided index and filters list based on permission.
   * @param {int} qIndex
   */
  getQuestionsAfter = qIndex => this.state.localScript.filter(this.props.questionFilterFunction).slice(qIndex + 1);

  /**
   * Returns list of questions in the PROVIDED SCRIPT that come after the provided index and filters list based on permission.
   * @param {int} qIndex
   * @param {array} script
   */
  getQuestionsAfterInArray = (qIndex, script) => script.filter(this.props.questionFilterFunction).slice(qIndex + 1);


  render() {
    const { list, active, selectedCampaignIndex, questionFilterFunction, showPanelQuestions } = this.props;
    const { warnings, validated } = this.state;
    const phones = Object.keys(list);

    let varsAvailable = '';
    if (phones.length > 0) {
      if (list[phones[0]].misc !== null) {
        var keys = Object.keys(list[phones[0]].misc);
        varsAvailable = "{" + keys.join("}, {") + "}";
      }
    }

    // if there's no panel questions in the script disable the routing checkbox
    // NOTE This avoids the questionFilterFunction prop because the checkbox should still be active if the user can't add questions.
    const disablePanelCheckbox = !this.state.localScript.filter(q => PANEL_QUESTION_IDS.includes(q.id)).length;

    let questionTypes = [...QUESTION_TYPES];
    if (showPanelQuestions) {
      questionTypes = [...QUESTION_TYPES, ...ADMIN_QUESTION_TYPES];
    }

    return (
      <div className="button-container">
        <button onClick={this.toggleOpen} className="button is-info">
          {this.state.localScript.length ? "Edit " : "Create "}
          {this.props.subject}
        </button>

        {/* This loads the media that's available to the campaign */}
        <MediaListContainer initParams={[selectedCampaignIndex]} />

        <Modal
          isOpen={this.state.open}
          className={"modal" + (this.state.open ? " is-active" : "")}
        >
          <div className="modal-background" onClick={this.toggleOpen} />
          <div className="modal-card" style={{width: "65%", minHeight: "80%"}}>
            <header className="modal-card-head">
              <p className="pr-2">
                Edit {this.props.subject}
              </p>
              <ToggleButton
                value={ this.state.jsonEditorOpen || false }
                inactiveLabel={'Form'}
                activeLabel={'JSON'}
                onToggle={(value) => {
                this.setState({
                  jsonEditorOpen: !value,
                })
              }}/>
            </header>
            <section className="modal-card-body">

            {this.state.jsonEditorOpen ?
              <ScriptErrorBoundary>
                <JsonEditor script={this.state.localScript} handleChange={this.handleJsonScriptChange} />
              </ScriptErrorBoundary>
                :
              <ScriptErrorBoundary>
                <div>
                  <div className="script-editor-note"><strong>Available variables: </strong>
                    <span className="variable-list">{varsAvailable ? varsAvailable : '[none]'}</span>
                  </div>
                  <hr />
                  <table className="flow-logic">
                    <thead>
                      <tr><td className="conditional">Initial Conditional</td>
                      <td>1<sup>st</sup> Q</td></tr>
                    </thead>
                    <tbody>
                      {this.state.initialConditionals.map((logic, cIndex) =>
                      {
                        return (<tr >
                        <td className="conditional">
                          <input
                              name="condition"
                              type="text"
                              rows="1"
                              className="input"
                              value={logic.condition}
                              onChange={e => this.handleInitialConditionalChange(cIndex, e)}
                              id={`initial_conditional_condition_input_${cIndex}`}
                            />
                            {warnings && validated && <p className="help is-danger">{warnings.initialConditionals[cIndex].condition}</p>}
                        </td>
                        <td className="goto">
                          <select
                            name="goto"
                            className="dropdown"
                            value={logic.goto}
                            onChange={e => this.handleInitialConditionalChange(cIndex, e)}
                            id={`initial_conditional_select_dropdown_${cIndex}`}
                          >
                            {this.state.localScript.filter(questionFilterFunction).map((q, qid) =>
                              <option key={`initial-${cIndex}-${qid}`} value={q.id}>{q.id}</option>
                            )}
                          </select>
                        {warnings && validated && <p className="help is-danger">{warnings.initialConditionals[cIndex].goto}</p>}
                        </td>
                        <td className="action-button">
                          {/* <button
                            className="button is-small is-rounded is-danger"
                            onClick={() => this.deleteInitialConditional(cIndex)}
                          >
                            &minus;
                          </button> */}
                          <TimesButton
                              callback={() => this.deleteInitialConditional(cIndex)}
                              className="danger"
                              size="lg"
                          />
                        </td>
                      </tr>)}
                      )}
                      <tr onClick={() => this.addInitialConditional()} id="add_initial_conditional_row">
                        <td colSpan="2"></td>
                        <td className="action-button">
                          {/* <button className="button is-small is-rounded is-success">
                            +
                          </button> */}
                          <PlusButton
                            className="success"
                            size="lg"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="conditional">
                          <input
                              name="text"
                              type="text"
                              rows="1"
                              className="input"
                              value="DEFAULT"
                              disabled={true}
                            />
                        </td>
                        <td className="goto">
                          <select
                            className="dropdown"
                            value={this.state.defaultFirstQuestionId}
                            onChange={this.handleDefaultFirstQuestionChange}
                            id="default_first_question_selection_dropdown"
                          >
                            {this.state.localScript.filter(questionFilterFunction).map(q =>
                              <option key={`default-first-question-${q.id}`} value={q.id}>{q.id}</option>
                            )}
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <article className="media">
                    <div className="media-content" />
                    <div className="media-right" style={{display: "inline-block", position: "relative", top: "-37px"}}>
                      <AddQuestion
                        addQuestion={this.addQuestion}
                        qid="first"
                        // This adds the question as 0 index
                        qIndex={-1}
                        showAdminQuestionTypes={showPanelQuestions}
                        // permission={this.props.permission}
                      />
                    </div>
                  </article>
                  {this.state.localScript.filter(questionFilterFunction).map((q, i) => (
                    <Question
                      q={q}
                      qIndex={i}
                      phones={phones}
                      addQuestion={this.addQuestion}
                      deleteQuestion={this.deleteQuestion }
                      moveQuestion={this.moveQuestion }
                      handleQuestionIdChange={this.handleQuestionIdChange }
                      handleQuestionTypeChange={this.handleQuestionTypeChange }
                      handleQuestionTextChange={this.handleQuestionTextChange }
                      handleQuestionMediaChange={this.handleQuestionMediaChange}
                      handleAnswerChange={this.handleAnswerChange }
                      deleteAnswer={this.deleteAnswer }
                      addAnswer={this.addAnswer}
                      handleConditionalChange={this.handleConditionalChange}
                      getQuestionsAfter={this.getQuestionsAfter}
                      deleteConditional={this.deleteConditional}
                      addConditional={this.addConditional }
                      handleDefaultNextChange={this.handleDefaultNextChange}
                      handleCheckboxChange={this.handleCheckboxChange}
                      copyQuestionToClipboard={this.copyQuestionToClipboard}
                      pasteQuestion={this.pasteQuestion}
                      filterPanelQuestionByPermission={questionFilterFunction}
                      disableUp={i === 0}
                      disableDown={i  === this.state.localScript.filter(getFilterPanelQuestionsFunc(false)).length - 1 }
                      disablePanelCheckbox={disablePanelCheckbox}
                      questionTypeList={questionTypes}
                      showPanelQuestions={showPanelQuestions}

                      {...this.state}
                      {...this.props}
                      />))}
                  {warnings && validated && warnings.terminatingQuestion && <p className="message is-danger">There are no terminating questions.</p>}
                  {warnings && validated && warnings.closingQuestion && <p className="message is-danger">There are no closing questions.</p>}
                </div>
              </ScriptErrorBoundary>
            }
            </section>
            <footer className="modal-card-foot">
              <BulmaButton
                id="save_button"
                customClass="is-info"
                onClick={this.save}
                loading={this.props.loading}
              >
                Save & Close
              </BulmaButton>
              <button className="button is-primary" id="validate_button" onClick={this.validateScript}>
                Validate
              </button>
              <button className="button" id="cancel_button" onClick={this.toggleOpen}>
                Cancel
              </button>

              {this.props.error && <p className="help is-size-6 is-danger">Failed to save script changes! This could mean another user has modified the campaign data.</p>}
              
              <button className="button push-right" id="randomize_button" onClick={() => this.props.getRandomListEntries(5)}>
                Randomize Sample
              </button>
              
            </footer>
          </div>
        </Modal>
      </div>
    );
  }
}

ScriptEditorModal.defaultProps = {
  initialConditionals: [],
  script: [],
  list: {}
}

const mapStateToProps = state => ({
  ...state.selectedCampaign.updateCampaignData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateScript
    },
    dispatch
  );

export default 
  connect(
  mapStateToProps,
  mapDispatchToProps
)(ScriptEditorModal);
