import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updateCampaignFetchData } from "../../redux/SelectedCampaign/actions";
import ConfirmActionModal from "../modals/ConfirmActionModal";

function ToggleLimitNewPerHour({ campaignid, limitNewConversations, updateCampaignFetchData }) {
    const modalMessage = limitNewConversations ?
        // COMBAK: Make sure this message is what we actually want to display
        "Are you sure you want to disable limiting new conversations per hour? This will allow texters to send as many new intros as they can."
        : "Are you sure you want to enable limiting new conversations per hour? This will prevent texters from initiating new conversations after the hourly limit is reached.";

  return (
    <ConfirmActionModal
      title="Confirm Toggle Limit New Conversations"
      message={modalMessage}
      actionName={`${limitNewConversations ? "Disable" : "Enable"} Hourly New Convo Limit`}
      buttonClass={`button ${limitNewConversations ? "is-danger" : "is-primary"} is-fullwidth`}
      actionCallback={() => updateCampaignFetchData(campaignid, { limit_new_first_attempts_hourly: !limitNewConversations })}
    />
  );
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
        updateCampaignFetchData,
        },
        dispatch
);

export default connect(null, mapDispatchToProps)(ToggleLimitNewPerHour);  // Exporting the connected component