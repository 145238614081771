import React from "react";
import PropTypes from "prop-types";

// TODO: remeove this after confirming that it is not used
// import { addNotificationTypeToFactory } from "../NotificationFactoryV2";
// addNotificationTypeToFactory("SimpleNotification", SimpleNotification);

const SimpleNotification = ({ severity, message, fadeOut, clear, ...props }) => {

  // map severity to Bulma class
  const severityClass = {
    SUCCESS: "is-success",
    WARNING: "is-warning",
    ERROR: "is-danger",
  }[severity];

  // set fadeOutTimer to 15 seconds, if used
  const fadeOutSeconds = fadeOut ? 15 : 0;
  if (fadeOutSeconds > 0) {
    setTimeout(() => {
      clear();
    }, fadeOutSeconds * 1e3);
  }

  return (
      <div className={`s160-notif notification ${severityClass}`}>
          <button className="delete" onClick={clear} />
          <div className="notif-content">
            {message}
          </ div>
      </div>
  );
};

SimpleNotification.propTypes = {
  severity: PropTypes.oneOf(["SUCCESS", "WARNING", "ERROR"]).isRequired,
  message: PropTypes.string.isRequired,
  fadeOut: PropTypes.bool,
  clear: PropTypes.func,
};

export default SimpleNotification;