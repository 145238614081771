import React, { useState } from "react";
import { Flexgrid } from "../common/CheckboxGrid/CheckboxGrid";
import { ErrorNotification } from "../generic/Notifications/ErrorNotification";
import { WarningNotification } from "../generic/Notifications/WarningNotification";
import ConfirmActionModal from "../modals/ConfirmActionModal";
import { StrataData } from "./StrataData";
import ResponseRateCheckbox from "./ResponseRateCheckbox";
import UseWebCompletesModal from "./UseWebCompletesModal";
import { BulmaButtonProper } from "../generic/Buttons/BulmaButtonProper";
import { ResetTargetsButton } from "./ResetTargetsButton";
import { ResetProportionsButton } from "./ResetWeightsButton";
import QuotasFileUpload from "./QuotasFileUpload";
import MultiSortingDisplay from "../common/MultiSortingDisplay";

export const Quotas = ({
  active,
  headers,
  fields,
  strataTable,
  totals,
  checkboxes,
  onCheckboxChange,
  generateStrataTable,
  update,
  clearStrataTable,
  loading,
  error,
  errorMessage,
  errorCode,
  refresh,
  trgtPropWarning,
  recipientListLength,
  setFieldAll,
  updateLock,
  userPermissions,
}) => {
  if (!recipientListLength) {
    return (
      <WarningNotification condition={true}>
        Upload a recipient list to access quota tools
      </WarningNotification>
    );
  }
  
  const isActiveCampaign = active !== "deactivated";
  const strataExist = !error && !loading && strataTable && strataTable.length;


  return (
    <div className="quotas-panel">

      {isActiveCampaign && (
        <div className="strata-fields">
          <h3 className="title is-4">Select fields to stratify sample on:</h3>
          <div>
            <Flexgrid items={checkboxes} onChange={onCheckboxChange} disabled={strataExist} />
          </div>
          <br />
          {!strataExist && <div style={{ clear: "both" }}>
            <ConfirmActionModal
              actionName="Generate Strata From Fields"
              actionCallback={generateStrataTable}
              buttonClass="button is-light "
              message="This will override any existing strata. Are you sure you want to proceed?"
              loading={loading}
            />
          </div>}
          <hr />
        </div>
      )}

      {strataExist && (
        <>
          {isActiveCampaign && (
            <div className="strat-reset-buttons mb-5">
              <ResetTargetsButton setFieldAll={setFieldAll} />
              <ResetProportionsButton setFieldAll={setFieldAll} />
            </div>
          )}

          <MultiSortingDisplay
            list={strataTable}
            fieldList={headers.map(h => h.accessor)}
            defaultField="strata_id"
            render={(sortedList, addField) =>
            (<StrataData
              list={sortedList}
              headers={headers.map(i => ({ ...i, onClick: fields.includes(i.accessor) ? () => addField(i.accessor) : undefined }))}
              totals={totals} />)
            }
          />
        </>
      )}
      <WarningNotification condition={error && errorCode === 404 || errorCode === 403}>
        No strata have been created
      </WarningNotification>
      <ErrorNotification condition={updateLock || (error && errorCode === 403)}>
        <b>LOCKED</b> Quotas are busy being created / updated, by a different user.
      </ErrorNotification>
      <ErrorNotification condition={error && errorCode === 500}>
        Something went wrong: {errorMessage}
      </ErrorNotification>
      {trgtPropWarning && (
        <p className="message is-danger">
          The target proportions you have entered don't add up to 100%
        </p>
      )}

      {isActiveCampaign && (
        <>
          <div className="field is-grouped mt-5">
            {!error && (
              <BulmaButtonProper
                loading={loading}
                onClick={update}
                customClass={"is-info"}
              >
                Save Targets
              </BulmaButtonProper>
            )}
            <BulmaButtonProper onClick={refresh} customClass="is-light">
              Refresh
            </BulmaButtonProper>
            <ConfirmActionModal
              actionName="Clear"
              actionCallback={clearStrataTable}
              buttonClass="button is-danger"
              message="This will clear all existing strata. Are you sure you want to proceed?"
              loading={loading}
            />
          </div>

          <hr />

          {userPermissions.includes("VIEW_QUOTAS_FILE_UPLOAD") && (
            <QuotasFileUpload updatingExisting={strataExist} />
          )}

          {!error && (
            <div>
              <hr />
              <h1 className="title is-4">Response Rate Adjustment</h1>
              <h1 className="subtitle is-6">
                <b>Response rate adjustment</b> upweights strata with low
                response rates to keep completion rates more even across strata.
              </h1>

              <ResponseRateCheckbox />
              <UseWebCompletesModal />
            </div>
          )}
        </>
      )}
    </div>
  );
};
