import React from "react";

import AnalyticsOverviewCards from "./AnalyticsOverview.cards";
import { DownloadAnalyticsData } from "./DownloadAnalyticsData";

import List from "../List";

const ANALYTICS_OVERVIEW_HEADERS = [
    {
        header: "Metric",
        accessor: "metric",
    },
    {
        header: "Value",
        accessor: "value",
    },
];

export default function AnalyticsOverview({ data = null}) {

    const {
        closed: textCompletes,
        web_closed: webCompletes,
        start: numberOfAttempted,
        contact: totalRecipients,
        percentcomplete,
        percentwebcomplete,
        percentstarted,
        percentengaged,
        percentterminated,
        percenterrors,
        total_errors,
        total_segments,
        agentHours,
        smsperagenthour,
        cost_per_complete,
        currentSpend,
        spendLimit,
    } = data || {};

    const listData = !data ? [] : [
        {
            metric: "Completes (text)",
            value: textCompletes,
        },
        {
            metric: "Completes (web)",
            value: webCompletes,
        },
        {
            metric: "Attempted",
            value: numberOfAttempted,
        },
        {
            metric: "Total Segments",
            value: total_segments,
        },
        {
            metric: "Agent Hour #",
            value: agentHours,
        },
        {
            metric: "% Engage",
            value: percentengaged,
        },
        {
            metric: "% Terminate",
            value: percentterminated,
        },
        {
            metric: "% Complete (text)",
            value: percentcomplete,
        },
        {
            metric: "% Complete (web)",
            value: percentwebcomplete,
        },
        {
            metric: "% Started",
            value: percentstarted,
        },
        {
            metric: "Total Recipients",
            value: totalRecipients, 
        },
        {
            metric: "SMS per Agent Hour",
            value: smsperagenthour,
        },
        {
            metric: "Total Spend",
            value: currentSpend,
        },
        {
            metric: "Total errors",
            value: total_errors,
        },
        {
            metric: "Spend per complete",
            value: cost_per_complete,
        },
    ];

    return (
        <div className="analytics-overview">
            <AnalyticsOverviewCards
                isTextToWeb={webCompletes > 0}
                percentcomplete={percentcomplete}
                currentSpend={currentSpend}
                spendLimit={spendLimit}
                percentstarted={percentstarted}
                percentengaged={percentengaged}
                percenterrors={percenterrors}
            />
            <div className="columns">
                <div className="column is-half is-offset-one-quarter">
                    <List
                        isNarrow={false}
                        list={listData}
                        headers={ANALYTICS_OVERVIEW_HEADERS}
                        className="fullwidth-body"
                    />
                </div>
            </div>
            {/* <DownloadAnalyticsData /> */}
        </div>
    );
}