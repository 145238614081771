import React from "react";
import TextToolsModal from "./TextToolsModal";
import ToggleNewConversations from "./ToggleNewConversations";
import ToggleLimitNewPerHour from "./ToggleLimitNewPerHour";

export const TextTools = ({
  active,
  campaignid,
  recipientbatchsize,
  actionablethreshold,
  assignOpenRecipients,
  limitNewConversationsPerHour,
  hourlyNewConversationLimit,
}) => {
  const isActiveCampaign = active !== "deactivated";

  return (
    <>
      <div className="tile is-parent is-vertical pb-0">
        <h4 className="title is-4 has-text-centered">TEXT SETTINGS</h4>
        <div className="tile is-parent">
          <div className="tile is-child">
            <div className="field">
              Recipient Batch Size
              <label className="label"> {recipientbatchsize}</label>
            </div>
            <div className="field">
              Threshold for loading more conversations
              <label className="label"> {actionablethreshold}</label>
            </div>
          </div>

          {isActiveCampaign && (
            <div className="tile is-child is-right">
              <TextToolsModal
                campaignid={campaignid}
                recipientbatchsize={recipientbatchsize}
                actionablethreshold={actionablethreshold}
                hourlyNewConversationLimit={hourlyNewConversationLimit}
              />
            </div>
          )}
        </div>

        <div className="tile is-parent">
          <div className="tile is-child">
            <div className="field">
              Allow Opening New Conversations
              <label className="label">
                {assignOpenRecipients ? "Yes" : "No"}
              </label>
            </div>
          </div>
          {isActiveCampaign && (
            <div className="tile is-child is-right">
              <div className="level-item">
                <ToggleNewConversations
                  campaignid={campaignid}
                  assignOpenRecipients={assignOpenRecipients}
                />
              </div>
            </div>
          )}
        </div>

        <div className="tile is-parent">
          <div className="tile is-child">
            <div className="field">
              Limit new conversations per hour
              <label className="label">
                {limitNewConversationsPerHour ? "Yes" : "No"}
              </label>
            </div>
          </div>
          {isActiveCampaign && (
            <div className="tile is-child is-right">
              <div className="level-item">
                <ToggleLimitNewPerHour
                  campaignid={campaignid}
                  limitNewConversations={limitNewConversationsPerHour}
                />
              </div>
            </div>
          )}
        </div>

        {limitNewConversationsPerHour && (
          <div className="tile is-parent">
            <div className="tile is-child">
              <div className="field">
                Hourly new conversation limit
                <label className="label">{hourlyNewConversationLimit}</label>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
