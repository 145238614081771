import React, { useEffect, useState } from "react";

import "./grids.css";

import { createDebouncer } from "../../../util/helper";

export const CheckboxGrid = ({ items, onChange, disabled=false }) => (
  <ul
    className="checkbox-grid"
    // style={{
    //   whiteSpace: "nowrap",
    //   overflow: "hidden",
    //   textOverflow: "ellipsis",
    // }}
  >
    {items.map((item, index) => (
      <li>
        <label className="checkbox" key={"check-" + index} title={item.name}>
        <input
          type="checkbox"
          onChange={(e) => onChange(e, index)}
          checked={item.value}
          disabled={disabled}
        />
          {/* <span 
          >
            </span> */}
          &nbsp;{item.name}
        </label>
      </li>
    ))}
  </ul>
);

// These are classes written by us since bulma CSS does not give access to the `flex-basis` property
const flexBasisClasses = { 2: 'flex-basis-50', 3: 'flex-basis-33', 4: 'flex-basis-25', 5: 'flex-basis-20' };
const flexgridDebouncer = createDebouncer(200);

export const Flexgrid = ({ items, onChange, disabled = false, ulClassNames = '', numberOfColumns = null, ulId, liClassNames = ''}) => {
  /*
    Why we are doing this: Just found out that the bulma imported right now (december 204) does not support the flexible grid system
    so we are creating our own grid system based on the number of columns we want to show in the grid, for now

    This isn't based on precise calculations, but just a rough estimate based on what makes sense on my screens
  */


  const [windowWidth, setWindowWith] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = flexgridDebouncer(function() {
      console.log("Resizing");
      setWindowWith(window.innerWidth);
    });

    window.addEventListener("resize", handleResize);
    return () => {
      console.log("Removing event listener");
      window.removeEventListener("resize", handleResize);
    }
  }, []);
  // Only calculate the number of columns is not specified
  if (!numberOfColumns) {
    if (windowWidth < 1016) {
      numberOfColumns = 2;
    } else if (windowWidth < 1366) {
      numberOfColumns = 3;
    } else if (windowWidth < 1700) {
      numberOfColumns = 4;
    } else numberOfColumns = 5;
  }

  const listItemFlexBasisClass = flexBasisClasses[numberOfColumns];

  return (
    <ul className={`columns is-flex-wrap-wrap ${ulClassNames}`} id={ulId}>
      {items.map((item, index) => (
        <li className={`is-flex-grow-0 is-flex-shrink-0 p-2 ${listItemFlexBasisClass} ${liClassNames}`} key={index}>
          <label className="checkbox" title={item.name}>
            <input
              type="checkbox"
              className="mr-1"
              onChange={(e) => onChange(e, index)}
              checked={item.value}
              disabled={!!disabled}
            />
            {item.name}
          </label>
        </li>
      ))}
    </ul>
  );
};